import React from "react";
import "./fs-how-to-play.scss";

function FsHowToPlay() {
  const translation = {
    gb: (
      <div className="fs-how-to-play">
        <div className="section">
          <div className="title">Overview : </div>

          <div className="content">
            <ul>
              <li>
                Four Seasons is played with a single deck of cards.The cards are
                dealt starting from Player 1, and four cards are dealt to
                everyone from Player to Banker one by one.
              </li>
              <li>
                The objective of the game is to have the highest hand over the
                lower hand.
              </li>
              <li>
                Special card type jackpot, win the jackpot based on the type of
                four cards
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">How To Play : </div>

          <div className="content">
            <ul>
              <li>One Banker and Three Players</li>
              <li>
                Players can place bets, and the amount is automatically divided
                into first and last bets.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Betting options and odds : </div>

          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>Banker</th>
                <th>Player 1</th>
                <th>Player 2</th>
                <th>Player 3</th>
              </tr>

              <tr>
                <td>Royal Straight Flush (Jackpot) </td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
              </tr>

              <tr>
                <td>Straight Flush (Jackpot) </td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
              </tr>

              <tr>
                <td>Four of A Kind (Jackpot) </td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
              </tr>

              <tr>
                <td>Three of A Kind (Jackpot) </td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
              </tr>

              <tr>
                <td>Flush (Jackpot) </td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
              </tr>

              <tr>
                <td>Straight (Jackpot) </td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
              </tr>

              <tr>
                <td>Two Pair (Jackpot) </td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
              </tr>

              <tr>
                <td>Head Car </td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
              </tr>

              <tr>
                <td>Tail Car </td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">Game Rules : </div>

          <div className="content">
            <ul>
              <li>Banker and three players compare cards</li>
              <li>
                Comparing the points of first card, and then comparing the
                points of back card with higher and lower points
              </li>
              <li>
                If there is no pair or above card types, the maximum points of
                the three cards will be compared. If the maximum points of the
                three cards are the same, the maximum points of the three cards
                will be compared. Compare the second largest number of points
                and so on until the largest different number of points is found.
              </li>
              <li>
                If the banker's and player's four cards have exactly the same
                points, the original bet will be refunded.
              </li>
              <li>
                Card sizes range from Royal Flush to the smallest pair, with the
                largest being ACE and the smallest being 2.
              </li>
              <li>
                Special card types are divided into large and small. Large
                special card types can win jackpots, while small special card
                types can help you win easily.
              </li>
              <li>
                If both the banker and the player have special card types, they
                need to compare the special card types in the basic gameplay,
                and the jackpot will be calculated separately.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Normal card type head card size : </div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "none",
                  textAlign: "center",
                  fontSize: "1rem",
                }}
              >
                A {">"} K {">"} Q {">"} J {">"} 10 {">"} 9 {">"} 8 {">"} 7 {">"}{" "}
                6 {">"} 5 {">"} 4 {">"} 3 {">"} 2
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Normal card type tail size : </div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                Small Royal Flush {">"} Small Straight Flush {">"} Small Flush{" "}
                {">"}
                Small Straight {">"} Pair {">"} High Card
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Special card size : </div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                Royal Straight Flush {">"} Straight Flush {">"} Iron Flush {">"}{" "}
                Royal Flush {">"} Royal Flush {">"} Three of a Kind {">"} Two
                Pair
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Road Map Description : </div>

          <div className="content">
            <table>
              <tr>
                <th>Shortening</th>
                <th>Meaning</th>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>H</td>
                <td>High Card</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>P</td>
                <td>Pair</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SS</td>
                <td>Small Straight</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SF</td>
                <td>Small Flush</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>J</td>
                <td>Jackpot</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    ),
    cn: (
      <div className="fs-how-to-play">
        <div className="section">
          <div className="title">概览：</div>

          <div className="content">
            <ul>
              <li>
                四季牌局使用一副牌进行游戏。从玩家1开始，逐一向每位玩家和庄家发牌，每人发四张牌。
              </li>
              <li>游戏的目标是拥有比对手更高的手牌。</li>
              <li>特殊卡型奖池，根据四张牌的类型赢取奖池。</li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">游戏玩法：</div>

          <div className="content">
            <ul>
              <li>一位庄家和三位玩家。</li>
              <li>玩家可以下注，金额会自动分为第一个和最后一个注。</li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">投注选项和赔率：</div>
          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>庄家</th>
                <th>玩家 1</th>
                <th>玩家 2</th>
                <th>玩家 3</th>
              </tr>

              <tr>
                <td>皇家同花顺 (积宝) </td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
              </tr>

              <tr>
                <td>同花顺 (积宝) </td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
              </tr>

              <tr>
                <td>铁支 (积宝) </td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
              </tr>

              <tr>
                <td>三条 (积宝) </td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
              </tr>

              <tr>
                <td>同花 (积宝) </td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
              </tr>

              <tr>
                <td>顺子 (积宝) </td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
              </tr>

              <tr>
                <td>两对 (积宝) </td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
              </tr>

              <tr>
                <td>头牌 </td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
              </tr>

              <tr>
                <td>尾牌 </td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">游戏规则：</div>

          <div className="content">
            <ul>
              <li>庄家和三名玩家比较牌面。</li>
              <li>
                比较第一张牌的点数，然后将背面的点数与更高和更低的点数进行比较。
              </li>
              <li>
                如果没有对子或以上的卡牌类型，将比较三张牌的最大点数。如果三张牌的最大点数相同，
                将比较三张牌的最大点数。比较第二大的点数，以此类推，直到找到最大不同的点数。
              </li>
              <li>如果庄家和玩家的四张牌点数完全相同，原始下注将被退还。</li>
              <li>
                卡牌大小范围从皇家同花顺到最小的一对，最大的是A，最小的是2。
              </li>
              <li>
                特殊卡牌类型分为大和小。大特殊卡牌类型可以赢得奖池，而小特殊卡牌类型可以帮助您轻松获胜。
              </li>
              <li>
                如果庄家和玩家都有特殊卡牌类型，它们需要在基本游戏中比较特殊卡牌类型，
                奖池将被单独计算。
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">普通卡牌类型头牌大小：</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "none",
                  textAlign: "center",
                  fontSize: "1rem",
                }}
              >
                A {">"} K {">"} Q {">"} J {">"} 10 {">"} 9 {">"} 8 {">"} 7 {">"}{" "}
                6 {">"} 5 {">"} 4 {">"} 3 {">"} 2
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">普通卡牌类型尾牌大小：</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                小皇家同花顺 {">"} 小同花顺 {">"} 小同花 {">"} 小顺子 {">"} 一对{" "}
                {">"} 高牌
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">特殊卡牌大小：</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                皇家同花顺 {">"} 同花顺 {">"} 铁支 {">"} 皇家同花 {">"} 皇家同花{" "}
                {">"} 三条 {">"} 两对
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">路图说明：</div>

          <div className="content">
            <table>
              <tr>
                <th>缩写</th>
                <th>含义</th>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>H</td>
                <td>高牌</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>P</td>
                <td>一对</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SS</td>
                <td>小顺子</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SF</td>
                <td>小同花</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>J</td>
                <td>奖池</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    ),
    my: (
      <div className="fs-how-to-play">
        <div className="section">
          <div className="title">Gambaran Keseluruhan:</div>

          <div className="content">
            <ul>
              <li>
                Permainan Empat Musim dimainkan dengan satu dek kad. Kad-kad
                dibahagikan bermula dari Pemain 1, dan empat kad dibahagikan
                kepada semua dari Pemain kepada Banker satu persatu.
              </li>
              <li>
                Objektif permainan adalah untuk mempunyai tangan tertinggi.
              </li>
              <li>
                Jenis kad istimewa jackpot, menangi jackpot berdasarkan jenis
                empat kad.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Cara Bermain:</div>

          <div className="content">
            <ul>
              <li>Satu Banker dan Tiga Pemain</li>
              <li>
                Pemain boleh meletakkan pertaruhan, dan jumlahnya secara
                automatik dibahagi kepada pertaruhan pertama dan terakhir.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Pilihan Pertaruhan dan Peluang:</div>
          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>Banker</th>
                <th>Pemain 1</th>
                <th>Pemain 2</th>
                <th>Pemain 3</th>
              </tr>

              <tr>
                <td>Royal Straight Flush (Jackpot) </td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
              </tr>

              <tr>
                <td>Straight Flush (Jackpot) </td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
              </tr>

              <tr>
                <td>Four of A Kind (Jackpot) </td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
              </tr>

              <tr>
                <td>Three of A Kind (Jackpot) </td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
              </tr>

              <tr>
                <td>Flush (Jackpot) </td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
              </tr>

              <tr>
                <td>Straight (Jackpot) </td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
              </tr>

              <tr>
                <td>Two Pair (Jackpot) </td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
              </tr>

              <tr>
                <td>Head Car </td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
              </tr>

              <tr>
                <td>Tail Car </td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">Peraturan Permainan:</div>

          <div className="content">
            <ul>
              <li>Banker dan tiga pemain membandingkan kad.</li>
              <li>
                Membandingkan mata kad pertama, dan kemudian membandingkan mata
                kad belakang dengan mata lebih tinggi dan lebih rendah.
              </li>
              <li>
                Jika tiada sepasang atau jenis kad di atas, mata maksimum tiga
                kad akan dibandingkan. Jika mata maksimum tiga kad sama, mata
                maksimum tiga kad akan dibandingkan. Bandingkan mata kedua
                tertinggi dan seterusnya sehingga mata yang berbeza tertinggi
                ditemui.
              </li>
              <li>
                Jika empat kad banker dan pemain mempunyai mata yang sama,
                pertaruhan asal akan dikembalikan.
              </li>
              <li>
                Saiz kad berkisar dari Royal Flush ke pasangan terkecil, dengan
                yang terbesar adalah ACE dan yang terkecil adalah 2.
              </li>
              <li>
                Jenis kad istimewa dibahagikan kepada besar dan kecil. Jenis kad
                istimewa besar boleh memenangi jackpot, manakala jenis kad
                istimewa kecil boleh membantu anda menang dengan mudah.
              </li>
              <li>
                Jika kedua-dua banker dan pemain mempunyai jenis kad istimewa,
                mereka perlu membandingkan jenis kad istimewa dalam permainan
                asas, dan jackpot akan dikira secara berasingan.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Saiz Kartu Biasa Head:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "none",
                  textAlign: "center",
                  fontSize: "1rem",
                }}
              >
                A {">"} K {">"} Q {">"} J {">"} 10 {">"} 9 {">"} 8 {">"} 7 {">"}{" "}
                6 {">"}5 {">"} 4 {">"} 3 {">"} 2
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Saiz Kartu Biasa Tail:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                Royal Flush Kecil {">"} Straight Flush Kecil {">"} Flush Kecil{" "}
                {">"}
                Straight Kecil {">"} Pasangan {">"} Kartu Tinggi
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Saiz Kartu Istimewa:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                Royal Straight Flush {">"} Straight Flush {">"} Iron Flush {">"}{" "}
                Royal Flush {">"} Royal Flush {">"} Three of a Kind {">"} Two
                Pair
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Penerangan Peta Jalan:</div>

          <div className="content">
            <table>
              <tr>
                <th>Pendekkan</th>
                <th>Makna</th>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>H</td>
                <td>Kad Tinggi</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>P</td>
                <td>Pasangan</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SS</td>
                <td>Kecil Straight</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SF</td>
                <td>Kecil Flush</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>J</td>
                <td>Jackpot</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    ),
    pt: (
      <div className="fs-how-to-play">
        <div className="section">
          <div className="title">Visão Geral:</div>

          <div className="content">
            <ul>
              <li>
                O jogo Four Seasons é jogado com um baralho de cartas. As cartas
                são distribuídas começando pelo Jogador 1, e quatro cartas são
                distribuídas para cada jogador, do Jogador ao Banqueiro, uma por
                vez.
              </li>
              <li>
                O objetivo do jogo é ter a mão mais alta sobre a mão adversária.
              </li>
              <li>
                Tipo especial de carta jackpot, ganhe o jackpot com base no tipo
                das quatro cartas.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Como Jogar:</div>

          <div className="content">
            <ul>
              <li>Um Banqueiro e Três Jogadores</li>
              <li>
                Os jogadores podem fazer apostas, e o valor é automaticamente
                dividido em apostas inicial e final.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Opções de Aposta e Odds:</div>
          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>Banqueiro</th>
                <th>Jogador 1</th>
                <th>Jogador 2</th>
                <th>Jogador 3</th>
              </tr>

              <tr>
                <td>Royal Straight Flush (Jackpot) </td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
              </tr>

              <tr>
                <td>Straight Flush (Jackpot) </td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
              </tr>

              <tr>
                <td>Four of A Kind (Jackpot) </td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
              </tr>

              <tr>
                <td>Three of A Kind (Jackpot) </td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
              </tr>

              <tr>
                <td>Flush (Jackpot) </td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
              </tr>

              <tr>
                <td>Straight (Jackpot) </td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
              </tr>

              <tr>
                <td>Two Pair (Jackpot) </td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
              </tr>

              <tr>
                <td>Head Car </td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
              </tr>

              <tr>
                <td>Tail Car </td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">Regras do Jogo:</div>

          <div className="content">
            <ul>
              <li>Banqueiro e três jogadores comparam as cartas.</li>
              <li>
                Comparando os pontos da primeira carta e, em seguida, comparando
                os pontos da carta de trás com pontos mais altos e mais baixos.
              </li>
              <li>
                Se não houver par ou tipos de cartas superiores, os pontos
                máximos das três cartas serão comparados. Se os pontos máximos
                das três cartas forem iguais, os pontos máximos das três cartas
                serão comparados. Compare o segundo maior número de pontos e
                assim por diante até encontrar a maior diferença de pontos.
              </li>
              <li>
                Se as quatro cartas do banqueiro e do jogador tiverem exatamente
                os mesmos pontos, a aposta original será reembolsada.
              </li>
              <li>
                Os tamanhos das cartas variam do Royal Flush para o par mais
                baixo, sendo o maior o Ás e o menor o 2.
              </li>
              <li>
                Os tipos especiais de cartas são divididos em grandes e
                pequenos. Tipos especiais de cartas grandes podem ganhar
                jackpots, enquanto tipos especiais de cartas pequenas podem
                ajudá-lo a ganhar facilmente.
              </li>
              <li>
                Se tanto o banqueiro quanto o jogador tiverem tipos especiais de
                cartas, eles precisam comparar os tipos especiais de cartas no
                jogo básico, e o jackpot será calculado separadamente.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Tamanho Normal da Carta - Carta Alta:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "none",
                  textAlign: "center",
                  fontSize: "1rem",
                }}
              >
                A {">"} K {">"} Q {">"} J {">"} 10 {">"} 9 {">"} 8 {">"} 7 {">"}{" "}
                6 {">"}5 {">"} 4 {">"} 3 {">"} 2
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Tamanho Normal da Carta - Carta Baixa:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                Royal Flush Pequeno {">"} Straight Flush Pequeno {">"} Flush
                Pequeno {">"}
                Straight Pequeno {">"} Par {">"} Carta Alta
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Tamanho Especial da Carta:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                Royal Straight Flush {">"} Straight Flush {">"} Iron Flush {">"}{" "}
                Royal Flush {">"} Royal Flush {">"} Three of a Kind {">"} Two
                Pair
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Descrição do Mapa de Estradas:</div>

          <div className="content">
            <table>
              <tr>
                <th>Abreviação</th>
                <th>Significado</th>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>H</td>
                <td>Carta Alta</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>P</td>
                <td>Pasangan</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SS</td>
                <td>Sequência Pequena</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SF</td>
                <td>Flush Pequeno</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>J</td>
                <td>Jackpot</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    ),
    vn: (
      <div className="fs-how-to-play">
        <div className="section">
          <div className="title">Tổng Quan:</div>

          <div className="content">
            <ul>
              <li>
                Trò chơi Four Seasons được chơi với một bộ bài. Bài được phát
                bắt đầu từ Người Chơi 1, và bốn lá bài được phát cho mỗi người
                từ Người Chơi đến Người Ngân Hàng, một lượt một lá.
              </li>
              <li>
                Mục tiêu của trò chơi là có lá bài cao nhất so với lá bài thấp
                hơn.
              </li>
              <li>
                Loại bài đặc biệt jackpot, giành chiến thắng jackpot dựa trên
                loại của bốn lá bài.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Cách Chơi:</div>

          <div className="content">
            <ul>
              <li>Một Ngân Hàng và Ba Người Chơi</li>
              <li>
                Người chơi có thể đặt cược, số tiền tự động được chia thành cược
                đầu và cược cuối cùng.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Lựa Chọn Cược và Tỷ Lệ Thắng:</div>
          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>Ngân Hàng</th>
                <th>Người Chơi 1</th>
                <th>Người Chơi 2</th>
                <th>Người Chơi 3</th>
              </tr>

              <tr>
                <td>Royal Straight Flush (Jackpot) </td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
              </tr>

              <tr>
                <td>Straight Flush (Jackpot) </td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
              </tr>

              <tr>
                <td>Four of A Kind (Jackpot) </td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
              </tr>

              <tr>
                <td>Three of A Kind (Jackpot) </td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
              </tr>

              <tr>
                <td>Flush (Jackpot) </td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
              </tr>

              <tr>
                <td>Straight (Jackpot) </td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
              </tr>

              <tr>
                <td>Two Pair (Jackpot) </td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
              </tr>

              <tr>
                <td>Head Car </td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
              </tr>

              <tr>
                <td>Tail Car </td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">Quy Tắc Trò Chơi:</div>

          <div className="content">
            <ul>
              <li>Ngân Hàng và ba người chơi so sánh lá bài.</li>
              <li>
                So sánh điểm của lá bài đầu tiên, và sau đó so sánh điểm của lá
                bài phía sau với điểm cao và điểm thấp.
              </li>
              <li>
                Nếu không có cặp hoặc loại bài trở lên, sẽ so sánh điểm tối đa
                của ba lá bài. Nếu điểm tối đa của ba lá bài giống nhau, sẽ so
                sánh điểm tối đa của ba lá bài. So sánh số thứ hai lớn nhất và
                cứ như vậy cho đến khi tìm thấy sự khác biệt lớn nhất về điểm.
              </li>
              <li>
                Nếu bốn lá bài của ngân hàng và người chơi có cùng điểm, cược
                ban đầu sẽ được hoàn trả.
              </li>
              <li>
                Kích thước lá bài nằm trong khoảng từ Royal Flush đến cặp nhỏ
                nhất, với lá bài lớn nhất là Át và lá bài nhỏ nhất là 2.
              </li>
              <li>
                Loại bài đặc biệt được chia thành loại lớn và nhỏ. Loại bài đặc
                biệt lớn có thể giành được jackpot, trong khi loại bài đặc biệt
                nhỏ có thể giúp bạn dễ dàng thắng.
              </li>
              <li>
                Nếu cả ngân hàng và người chơi đều có loại bài đặc biệt, họ cần
                so sánh loại bài đặc biệt trong trò chơi cơ bản, và jackpot sẽ
                được tính riêng biệt.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">
            Kích Thước Bình Thường Của Loại Bài - Lá Bài Đầu:
          </div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "none",
                  textAlign: "center",
                  fontSize: "1rem",
                }}
              >
                A {">"} K {">"} Q {">"} J {">"} 10 {">"} 9 {">"} 8 {">"} 7 {">"}{" "}
                6 {">"}5 {">"} 4 {">"} 3 {">"} 2
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">
            Kích Thước Bình Thường Của Loại Bài - Lá Bài Cuối:
          </div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                Royal Flush Nhỏ {">"} Straight Flush Nhỏ {">"} Flush Nhỏ {">"}{" "}
                Straight Nhỏ {">"} Cặp {">"} Lá Bài Cao
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Kích Thước Đặc Biệt Của Loại Bài:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                Royal Flush {">"} Straight Flush {">"} Iron Flush {">"} Royal
                Flush {">"}
                Royal Flush {">"} Three of a Kind {">"} Two Pair
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">Mô Tả Sơ Đồ Đường:</div>

          <div className="content">
            <table>
              <tr>
                <th>Rút Gọn</th>
                <th>Nghĩa</th>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>H</td>
                <td>Carta Alta</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>P</td>
                <td>Pasangan</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SS</td>
                <td>Dãn Nhỏ</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SF</td>
                <td>Xì Bàng Nhỏ</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>J</td>
                <td>Jackpot</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    ),
    th: (
      <div className="fs-how-to-play">
        <div className="section">
          <div className="title">ภาพรวม:</div>

          <div className="content">
            <ul>
              <li>
                Four Seasons เล่นด้วยสำรับไพ่เดียว ไพ่ถูกแจกตั้งแต่ผู้เล่นที่ 1
                และแจกสี่ใบ ทุกคนตั้งแต่ผู้เล่นไปจนถึงแบงค์
                ลงท้ายด้วยหนึ่งใบต่อหนึ่งคน
              </li>
              <li>วัตถุประสงค์ของเกมคือการมีไพ่สูงกว่าไพ่ต่ำ</li>
              <li>ประเภทไพ่พิเศษ Jackpot, ชนะ Jackpot ตามประเภทของสี่ใบไพ่</li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">วิธีการเล่น:</div>

          <div className="content">
            <ul>
              <li>หนึ่งแบงค์และสามผู้เล่น</li>
              <li>
                ผู้เล่นสามารถวางเดิมพัน
                จำนวนจะถูกแบ่งโดยอัตโนมัติเป็นการวางเดิมพันแรกและสุดท้าย
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">ตัวเลือกการเดิมพันและอัตราการจ่าย:</div>
          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>แบงค์</th>
                <th>ผู้เล่น 1</th>
                <th>ผู้เล่น 2</th>
                <th>ผู้เล่น 3</th>
              </tr>

              <tr>
                <td>Royal Straight Flush (Jackpot) </td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
              </tr>

              <tr>
                <td>Straight Flush (Jackpot) </td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
              </tr>

              <tr>
                <td>Four of A Kind (Jackpot) </td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
              </tr>

              <tr>
                <td>Three of A Kind (Jackpot) </td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
              </tr>

              <tr>
                <td>Flush (Jackpot) </td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
              </tr>

              <tr>
                <td>Straight (Jackpot) </td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
              </tr>

              <tr>
                <td>Two Pair (Jackpot) </td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
              </tr>

              <tr>
                <td>Head Car </td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
              </tr>

              <tr>
                <td>Tail Car </td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">กฎเกม:</div>

          <div className="content">
            <ul>
              <li>แบงค์และผู้เล่นสามารถเปรียบเทียบไพ่</li>
              <li>
                เปรียบเทียบคะแนนของไพ่แรก แล้วเปรียบเทียบคะแนนของไพ่ด้านหลัง
                กับคะแนนที่สูงและต่ำ
              </li>
              <li>
                หากไม่มีคู่หรือประเภทไพ่ขึ้นไป
                จะเปรียบเทียบคะแนนสูงสุดของสามใบไพ่
                หากคะแนนสูงสุดของสามใบไพ่เท่ากัน
                จะเปรียบเทียบคะแนนสูงสุดของสามใบไพ่ เปรียบเทียบคะแนนที่สองสูงสุด
                และต่อไปเรื่อย ๆ จนกระทั่งพบคะแนนที่ต่างกันมากที่สุด
              </li>
              <li>
                หากสี่ใบไพ่ของแบงค์และผู้เล่นมีคะแนนเหมือนกันที่สุด
                การเดิมพันเริ่มต้นจะถูกคืน
              </li>
              <li>
                ขนาดของไพ่ครอบคลุมตั้งแต่ Royal Flush ถึงคู่น้อยที่สุด
                โดยใหญ่ที่สุดคือ A และเล็กที่สุดคือ 2
              </li>
              <li>
                ประเภทไพ่พิเศษถูกแบ่งเป็นใหญ่และเล็ก ประเภทไพ่พิเศษใหญ่สามารถชนะ
                แจ็คพ็อตได้ ในขณะที่ประเภทไพ่พิเศษเล็กสามารถช่วยให้คุณชนะได้ง่าย
              </li>
              <li>
                หากทั้งแบงค์และผู้เล่นมีประเภทไพ่พิเศษ
                พวกเขาต้องเปรียบเทียบประเภทไพ่พิเศษ ในการเล่นเกมหลัก
                และแจ็คพ็อตจะถูกคำนวณโดยแยกกัน
              </li>
            </ul>
          </div>

          <div className="section">
            <div className="title">ขนาดไพ่ปกติประเภทหัว:</div>

            <div className="content">
              <ul>
                <li
                  style={{
                    listStyleType: "none",
                    textAlign: "center",
                    fontSize: "1rem",
                  }}
                >
                  A {">"} K {">"} Q {">"} J {">"} 10 {">"} 9 {">"} 8 {">"} 7{" "}
                  {">"} 6 {">"} 5 {">"} 4 {">"} 3 {">"} 2
                </li>
              </ul>
            </div>
          </div>

          <div className="section">
            <div className="title">ขนาดไพ่ปกติประเภทท้าย:</div>

            <div className="content">
              <ul>
                <li
                  style={{
                    listStyleType: "disc",
                    fontSize: "1rem",
                  }}
                >
                  Royal Flush {">"} Straight Flush {">"} Iron Flush {">"} Royal
                  Flush {">"} Royal Flush {">"} Three of a Kind {">"} Two Pair
                </li>
              </ul>
            </div>
          </div>

          <div className="section">
            <div className="title">ขนาดไพ่พิเศษ:</div>

            <div className="content">
              <ul>
                <li
                  style={{
                    listStyleType: "disc",
                    fontSize: "1rem",
                  }}
                >
                  ไพ่สูงสุดแบบ Royal Straight Flush {">"} ไพ่สูงสุดแบบ Straight
                  Flush {">"} ไพ่สูงสุดแบบ Iron Flush {">"} ไพ่สูงสุดแบบ Royal
                  Flush {">"} ไพ่สูงสุดแบบ Royal Flush {">"} ไพ่สูงสุดแบบ Three
                  of a Kind {">"} ไพ่สูงสุดแบบ Two Pair
                </li>
              </ul>
            </div>
          </div>

          <div className="section">
            <div className="title">คำอธิบายแผนที่ถนน:</div>

            <div className="content">
              <table>
                <tr>
                  <th>ย่อ</th>
                  <th>ความหมาย</th>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  <td>H</td>
                  <td>ไพ่สูงสุด</td>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  <td>P</td>
                  <td>คู่</td>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  <td>SS</td>
                  <td>ลำดับเล็ก</td>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  <td>SF</td>
                  <td>ฟลัชเล็ก</td>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  <td>J</td>
                  <td>แจ็คพ็อต</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    ),
    kr: (
      <div className="fs-how-to-play">
        <div className="section">
          <div className="title">개요:</div>

          <div className="content">
            <ul>
              <li>
                Four Seasons는 한 세트의 카드로 플레이됩니다. 카드는 1번
                플레이어부터 시작하여 모든 플레이어 및 뱅커에게 한 번에 4장씩
                나누어지며 각각에게 한 장씩 나눠집니다.
              </li>
              <li>게임의 목적은 상대방보다 높은 패를 가지는 것입니다.</li>
              <li>
                특별한 카드 유형 잭팟은 네 장의 카드 유형에 기반하여 잭팟을
                획득합니다.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">게임 방법:</div>

          <div className="content">
            <ul>
              <li>뱅커와 세 명의 플레이어가 참여합니다.</li>
              <li>
                플레이어는 베팅을 할 수 있으며, 금액은 자동으로 첫 번째 및
                마지막 베팅으로 나누어집니다.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">베팅 옵션 및 배율:</div>
          <div className="content">
            <table>
              <tr>
                <th></th>
                <th>뱅커</th>
                <th>플레이어 1</th>
                <th>플레이어 2</th>
                <th>플레이어 3</th>
              </tr>

              <tr>
                <td>Royal Straight Flush (잭팟) </td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
                <td>1 : 3000</td>
              </tr>

              <tr>
                <td>Straight Flush (잭팟) </td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
                <td>1 : 600</td>
              </tr>

              <tr>
                <td>Four of A Kind (Jackpot) </td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
                <td>1 : 300</td>
              </tr>

              <tr>
                <td>Three of A Kind (Jackpot) </td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
                <td>1 : 100</td>
              </tr>

              <tr>
                <td>Flush (Jackpot) </td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
                <td>1 : 50</td>
              </tr>

              <tr>
                <td>Straight (Jackpot) </td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
                <td>1 : 30</td>
              </tr>

              <tr>
                <td>Two Pair (Jackpot) </td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
                <td>1 : 20</td>
              </tr>

              <tr>
                <td>Head Car </td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
                <td>1 : 1</td>
              </tr>

              <tr>
                <td>Tail Car </td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
                <td>1 : 0.95</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="section">
          <div className="title">게임 규칙:</div>

          <div className="content">
            <ul>
              <li>뱅커와 세 명의 플레이어는 카드를 비교합니다.</li>
              <li>
                첫 번째 카드의 점수를 비교하고, 그런 다음 뒷면 카드의 점수를 더
                높은 쪽과 낮은 쪽으로 비교합니다.
              </li>
              <li>
                짝이나 그 이상의 카드 유형이 없으면 세 장의 카드 중 최대 점수를
                비교합니다. 세 장의 카드 중 최대 점수가 동일한 경우, 세 장의
                카드 중 최대 점수를 다시 비교합니다. 두 번째로 큰 점수를
                비교하고 가장 큰 다른 점수가 발견될 때까지 반복합니다.
              </li>
              <li>
                뱅커와 플레이어의 네 장의 카드가 정확히 동일한 점수를 가지면
                원래 베팅이 환불됩니다.
              </li>
              <li>
                카드 크기는 로열 플러시에서 가장 작은 한 쌍까지이며, 가장 큰
                것은 A이고 가장 작은 것은 2입니다.
              </li>
              <li>
                특수한 카드 유형은 크고 작게 나뉩니다. 큰 특수 카드 유형은
                잭팟을 획득할 수 있으며, 작은 특수 카드 유형은 쉽게 이기도록
                도와줍니다.
              </li>
              <li>
                뱅커와 플레이어가 모두 특수한 카드 유형을 가지고 있다면, 기본
                게임에서 특수한 카드 유형을 비교하고, 잭팟은 별도로 계산됩니다.
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">일반 카드 유형 헤드 카드 크기:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "none",
                  textAlign: "center",
                  fontSize: "1rem",
                }}
              >
                A {">"} K {">"} Q {">"} J {">"} 10 {">"} 9 {">"} 8 {">"} 7 {">"}{" "}
                6 {">"} 5 {">"} 4 {">"} 3 {">"} 2
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">일반 카드 유형 테일 카드 크기:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                작은 로열 플러시 {">"} 작은 스트레이트 플러시 {">"} 작은 플러시{" "}
                {">"} 작은 스트레이트 {">"} 한 쌍 {">"} 하이 카드
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">특수 카드 크기:</div>

          <div className="content">
            <ul>
              <li
                style={{
                  listStyleType: "disc",
                  fontSize: "1rem",
                }}
              >
                로열 스트레이트 플러시 {">"} 스트레이트 플러시 {">"} 아이언
                플러시 {">"} 로열 플러시 {">"} 로열 플러시 {">"} 쓰리 오브 어
                카인드 {">"} 투 페어
              </li>
            </ul>
          </div>
        </div>

        <div className="section">
          <div className="title">로드맵 설명:</div>

          <div className="content">
            <table>
              <tr>
                <th>약어</th>
                <th>의미</th>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>H</td>
                <td>하이 카드</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>P</td>
                <td>한 쌍</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SS</td>
                <td>작은 순열</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>SF</td>
                <td>작은 플러시</td>
              </tr>

              <tr style={{ textAlign: "center" }}>
                <td>J</td>
                <td>잭팟</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    ),
  };

  return translation[localStorage.getItem("i18nextLng")] ?? translation["gb"];
}

export default FsHowToPlay;
