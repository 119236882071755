import React from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { formatDateTime, formatNumber, formatString } from "../../../../../../utils/Formatter";

import "./sicbo.scss";
import { Space, Table, Typography } from "antd";

import Dice from "../../../../../../page-game/sicbo/component/Dice";

function Sicbo({ record, data }) {
    const { t } = useTranslation();

    const columns = [
        {
            title: t("betCode"),
            dataIndex: "betCodeName",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return formatString(text);
            },
        },
        {
            title: t("bet"),
            dataIndex: "bet",
            ellipsis: true,
            align: "center",
            render: (text) => {
                return "MYR " + formatNumber(text);
            },
        },
        {
            title: t("win"),
            dataIndex: "win",
            ellipsis: true,
            align: "center",
            render: (text) => {
                if (record.win > record.bet) {
                    return <p style={{ color: "gold" }}>MYR {formatNumber(text)}</p>;
                } else {
                    return "MYR " + formatNumber(text);
                }
            },
        },
    ];

    return (
        <Space direction="vertical" style={{ width: "100%" }} className="game-hitsory-detail">
            <Typography.Text>Game Resolved: {formatDateTime(moment(record?.endDate).add(8, "h"))}</Typography.Text>
            <Typography.Text>Game Number: {record?.gameSummarySrno}</Typography.Text>

            <div className="dice-result-wrapper">
                {data?.data?.split(",")?.map((item, index) => (
                    <div className="dice-wrapper" key={index}>
                        <Dice point={Number(item)} />
                    </div>
                ))}
            </div>

            <Table
                bordered
                columns={columns}
                dataSource={data?.data2}
                rowKey="betCode"
                scroll={{ x: true }}
                pagination={false}
                summary={(pageData) => {
                    let totalBet = 0;
                    let totalWin = 0;
                    pageData.forEach(({ bet, win }) => {
                        totalBet += bet;
                        totalWin += win;
                    });
                    return (
                        <Table.Summary.Row align="center">
                            <Table.Summary.Cell index={0}>TOTAL</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>MYR {formatNumber(totalBet)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>MYR {formatNumber(totalWin)}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }}
            />
        </Space>
    );
}

export default Sicbo;
